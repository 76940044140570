import { globalLogger } from './global-logger';
import { testIsUndefined } from '../../lib/test-and-assert/test-base';
import localTranslator from '../../vendor/translator/translator';

/* global Translator */

/**
 * This tries to translate the string with the global translator in the main bundles.
 * If something happens, the original string will be returned
 *
 * @param {String} message
 * @param {Object} [replaceValues]
 * @return {String}
 */
let translate = function (message, replaceValues) {
    try {
        return doTranslation(Translator, message, replaceValues);
    } catch (e) {
        try {
            return doTranslation(localTranslator, message, replaceValues);
        } catch (e) {
            globalLogger.log('translator exception', e.message);
        }
    }

    return message;
};

function doTranslation(translator, message, replaceValues) {
    let translatedMessage = translator.trans(message);

    if (translatedMessage.trim() === '') {
        translatedMessage = message;
    }

    if (replaceValues) {
        for (const [name, value] of Object.entries(replaceValues)) {
            translatedMessage = translatedMessage.replace(name, value);
        }
    }

    return translatedMessage;
}

export { translate };
